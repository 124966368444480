<template>
<div>
    <div class="dashboard-widget-tabs dashboard-widget-pages">
        <div class="dashboard-widget-tabs-content padding-m" style="margin: 0;border: 0;padding: 0;">
            <h2>
                <!-- <i class="uil uil-create-dashboard"></i> -->
                <svg class="rightnav-svg rightnav-svg-page" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">viewBox="0 0 512 512">
                    <path class="fa-primary" d="M408 360H472C485.3 360 496 370.7 496 384C496 397.3 485.3 408 472 408H408V472C408 485.3 397.3 496 384 496C370.7 496 360 485.3 360 472V408H296C282.7 408 272 397.3 272 384C272 370.7 282.7 360 296 360H360V296C360 282.7 370.7 272 384 272C397.3 272 408 282.7 408 296V360z" />
                    <path class="fa-secondary" d="M32 80C32 53.49 53.49 32 80 32H176C202.5 32 224 53.49 224 80V176C224 202.5 202.5 224 176 224H80C53.49 224 32 202.5 32 176V80zM32 336C32 309.5 53.49 288 80 288H176C202.5 288 224 309.5 224 336V432C224 458.5 202.5 480 176 480H80C53.49 480 32 458.5 32 432V336zM432 32C458.5 32 480 53.49 480 80V176C480 202.5 458.5 224 432 224H336C309.5 224 288 202.5 288 176V80C288 53.49 309.5 32 336 32H432z" /></svg>
                <span class="page-title-svg">{{ dashboard.title }}</span>
            </h2>
            <div class="dashboard-widget-h-btns" v-if="!editable">
                <router-link class="m-left m-top tgju-btn tgju-btn-warning hide-pages-mobile-btn" v-tooltip="'ویرایش'" :to="{ name: 'DashboardEdit', params: { id : dashboard.id }, }">
                    <i class="uil uil-setting" aria-hidden="true"></i>
                    <span class="hide-m-min">ویرایش</span>
                </router-link>
                <router-link :to="{ name: 'Dashboards'}" class="m-left m-top tgju-btn tgju-btn-light has-tooltip hide-pages-mobile-btn" v-tooltip="'لیست داشبوردها'">
                    <i class="uil uil-angle-left-b"></i>
                    <span class="hide-m-min">لیست داشبوردها</span>
                </router-link>
                <a href="" @click.prevent="open_help_modal()" class="m-left m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
            </div>
        </div>
    </div>

    <div class="mobile-btns-box show-pages-mobile-btn one-btn">
        <router-link class="tgju-btn tgju-btn-warning" v-tooltip="'ویرایش'" :to="{ name: 'DashboardEdit', params: { id : dashboard.id }, }">
            <i class="uil uil-setting" aria-hidden="true"></i>
            <span>ویرایش</span>
        </router-link>
        <router-link :to="{ name: 'Dashboards'}" class="tgju-btn tgju-btn-light has-tooltip" v-tooltip="'لیست داشبوردها'">
            <i class="uil uil-angle-left-b"></i>
            <span>لیست داشبوردها</span>
        </router-link>
    </div>

    <slot v-if="loading">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>

    <slot v-else>
        <template v-if="widgets.length">
            <div class="row tgju-widgets-row dashboard-widget-list widget-border-fix" :class="{ 'dashboard-page-loader': loading }">
                <template v-for="item in widgets">
                    <li class="tgju-widgets-block col-12 mb-m-2 draggable-element" :class="item.sizeClass" :key="item.widget.id" :data-widget-type="item.widget.type" :data-widget-id="item.widget.id" :data-widget-size="item.widget.size">
                        <div class="tgju-widget light">
                            <div class="list-widget  list-row bold list-row-title">
                                <div class="list-col l-title dot head-color no-border">
                                    <span>{{ item.widget.title }}</span>
                                </div>
                            </div>
                            <div class="tgju-widget-content full-padding">
                                <div class="tables-default normal overflow-unset dashboard-widget-min">
                                    <component v-bind:is="item.component" :full="true" :widget="item.widget" :data="item.data" :ref="`widget_${item.widget.id}`" wrapper="dashboard"></component>
                                </div>
                            </div>
                        </div>
                    </li>
                </template>
            </div>
        </template>
        <template v-else>
            <!-- در صورتی که ویجتی اد نشده بود این بخش نمایش باید داده شود -->
            <div class="row tgju-widgets-row widget-padding noWidget-block">
                <div class="col-12">
                    <div class="noWidget noContent">
                        <div>
                            <i class="uil uil-layer-group-slash noContent-icon" aria-hidden="true"></i>
                            <p class="noContent-title">در حال حاضر ویجتی برای نمایش وجود ندارد!</p>
                            <p class="noContent-description">
                                شما میتوانید به راحتی پس از ورود به بخش داشبورد ها و با انتخاب داشبورد مورد نظر به بخش ویرایش ورود کرده و منوی ایجاد ویجت ، ویجت مد نظر خود را اضافه کنید .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </slot>
</div>
</template>

<script>
// از این ویو برای نمایش اطلاعات داشبورد استفاده میشود
// و ویجت های آن داشبورد استفاده میشود
import draggable from 'vuedraggable'

export default {
    name: 'Dashboard',
    components: {
        draggable
    },
    data: function () {
        return {
            dashboard: {
                id: null,
                user_id: null,
                title: '',
                type: null,
                default: false,
                created_at: null,
            },
            widgets: [],
            loading: true,
            editable: false,
        }
    },

    mounted() {
        if (typeof this.$router.currentRoute.params.id != 'undefined') {
            this.dashboard.id = this.$router.currentRoute.params.id;
        }

        this.loadDashboard();

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },

    methods: {
        // لود کردن داشبورد
        // در لیست داشبورد هایی که در ستور ویو جی اس قرار دارند پیمایش میکنیم تا آی دی مورد نظر را بگیریم
        // به اضافه ی اطلاعات آن
        loadDashboard() {
            let dashboards = this.$store.state.dashboards;

            dashboards.forEach(dashboard => {
                if (this.dashboard.id && dashboard.id == this.dashboard.id) {
                    this.dashboard = dashboard;
                } else if (!this.dashboard.id && dashboard.default) {
                    this.dashboard = dashboard;
                }
            });

            if (!this.dashboard.id && dashboards) {
                this.dashboard = dashboards[0];
            }

            if (this.dashboard.id) {
                this.loadWidets();
            }
        },
        // لود کردن ویجت های داشبورد مورد نظر
        loadWidets() {
            this.$helpers.getWidgets({ dashboard_id: this.dashboard.id }).then((widgets) => {
                widgets.forEach((item, index) => {
                    if (!item.component) {
                        widgets.splice(index, 1);
                        return;
                    }

                    Vue.component(
                        item.component,
                        () => import(`@/components/${item.component}`)
                    );
                });

                this.widgets = widgets;
                this.loading = false;
            });
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Dashboards.vue',
                title: 'داشبورد‌ها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },

    }
}
</script>
